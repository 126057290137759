// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from '@modules/Auth/store/authSlice'
import chat from '@modules/Chat/store/chatSlice'
import topic from '@modules/widgetCentar/store/widgetCentarSlice'
import call from '@modules/VideoCall/store/videoCallSlice'

const rootReducer = { auth, chat, topic, call, navbar, layout }

export default rootReducer
