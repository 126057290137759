import axios from '@api/authAxios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const API_URL = '/api/topic/advisor'

const initialState = {
  topics: [],
  singleTopic: {},
  loading: false,
  success: false,
  error: false,
  message: '',
}

const handleError = (error) => {
  return (
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()
  )
}

// Get all topics
export const getAllTopics = createAsyncThunk(
  'topic/getAllTopics',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${API_URL}/all`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

// Create new topic
export const createNewTopic = createAsyncThunk(
  'topic/createNew',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/create`, data)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

// Delete topic
export const deleteTopic = createAsyncThunk(
  'topic/deleteTopic',
  async (id, thunkAPI) => {
    try {
      await axios.delete(`${API_URL}/delete/${id}`)

      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

// Get single topic
export const getSingleTopic = createAsyncThunk(
  'topic/getSingleTopic',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${API_URL}/get/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

// Get single topic
export const updateSingleTopic = createAsyncThunk(
  'topic/updateSingleTopic',
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axios.patch(`${API_URL}/update/${id}`, data)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

// Get single topic
export const updateTopicStatus = createAsyncThunk(
  'topic/updateTopicStatus',
  async ({ id, isActive }, thunkAPI) => {
    try {
      const response = await axios.patch(`${API_URL}/update-status/${id}`, {
        isActive,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

export const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    reset: () => initialState,
    resetSingleTopic: (state) => ({
      ...state,
      singleTopic: {},
    }),
  },
  extraReducers: (builder) => {
    builder
      // Get all topic
      .addCase(getAllTopics.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAllTopics.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.topics = action.payload.data
      })
      .addCase(getAllTopics.rejected, (state, action) => {
        state.loading = false
        state.error = true
        state.message = action.payload
        state.topics = {}
      })

      // Create new topic
      .addCase(createNewTopic.pending, (state, action) => {
        state.loading = true
      })
      .addCase(createNewTopic.fulfilled, (state, action) => {
        console.log(action.payload.data)
        state.success = true
        state.topics.push(action.payload.data)
        state.loading = false
      })
      .addCase(createNewTopic.rejected, (state, action) => {
        state.error = true
        state.message = action.payload
        state.loading = false
      })

      // Delete topic
      .addCase(deleteTopic.pending, (state, action) => {
        state.loading = true
      })
      .addCase(deleteTopic.fulfilled, (state, action) => {
        state.success = true
        state.topics = state.topics.filter(
          (topic) => topic.id !== action.payload
        )
        state.loading = false
      })
      .addCase(deleteTopic.rejected, (state, action) => {
        state.error = true
        state.message = action.payload
        state.loading = false
      })

      // Get single topic
      .addCase(getSingleTopic.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getSingleTopic.fulfilled, (state, action) => {
        state.success = true
        state.singleTopic = action.payload.data
        state.loading = false
      })
      .addCase(getSingleTopic.rejected, (state, action) => {
        state.error = true
        state.message = action.payload
        state.loading = false
      })

      // Update topic
      .addCase(updateSingleTopic.pending, (state, action) => {
        state.loading = true
      })
      .addCase(updateSingleTopic.fulfilled, (state, action) => {
        state.success = true
        state.topics = state.topics.map((el) =>
          el.id === action.payload.data.id ? action.payload.data : el
        )
        state.loading = false
      })
      .addCase(updateSingleTopic.rejected, (state, action) => {
        state.error = true
        state.message = action.payload
        state.loading = false
      })

      // Update Topic status
      .addCase(updateTopicStatus.pending, (state, action) => {
        state.loading = true
      })
      .addCase(updateTopicStatus.fulfilled, (state, action) => {
        state.success = true
        state.topics = state.topics.map((el) => {
          if (el.uuid === action.payload.data.uuid) {
            return {
              ...el,
              is_active: action.payload.data.is_active,
            }
          } else {
            return el
          }
        })
        state.loading = false
      })
      .addCase(updateTopicStatus.rejected, (state, action) => {
        state.error = true
        state.message = action.payload
        state.loading = false
      })
  },
})

export const { reset, resetSingleTopic } = topicSlice.actions
export default topicSlice.reducer

export const selectAllTopics = (state) => state.topic.topics
