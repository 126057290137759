import axios from '@api/authAxios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Get conversation with guest
export const getMessages = createAsyncThunk(
  'call/getMessages',
  async ({ topicId, clientId }, thunkAPI) => {
    try {
      const response = await axios.get(
        `/api/messages/advisor/topic/${topicId}/${clientId}`
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

const initialState = {
  callStarted: false,
  clientId: '',
  topicId: '',
  partnerTypeOffer: '',
  localTypeOffer: '',
  isRinging: false,
  isAcceptedOnAnotherDevice: false,
  isUserBusy: false,
  partnerSocketID: '',
  typeOfCall: '',
  offer: null,
  messages: null,
  isLoading: false,
  isError: true,
  isSuccess: true,
}

export const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    onOutcomingCall: (state, action) => {
      state.callStarted = true
      state.clientId = action.payload.clientId
      state.topicId = action.payload.topicId
      state.localTypeOffer = action.payload.type
    },
    onStopCalling: (state, action) => {
      state.callStarted = false
      state.clientId = ''
      state.topicId = ''
      state.localTypeOffer = ''
    },
    onIncomingCall: (state, action) => {
      state.callStarted = true
      state.isRinging = false
    },
    onStartRinging: (state, action) => {
      state.isRinging = true
      state.clientId = action.payload.clientId
      state.topicId = action.payload.topicId
      state.partnerTypeOffer = action.payload.type
      state.localTypeOffer = action.payload.type
      state.partnerSocketID = action.payload.target
    },
    onStopRinging: (state, action) => {
      state.isRinging = false
      state.clientId = ''
      state.topicId = ''
      state.partnerTypeOffer = ''
      state.partnerSocketID = ''
    },
    onAcceptCall: (state, action) => {
      state.partnerTypeOffer = action.payload.type
      state.partnerSocketID = action.payload.target
      state.typeOfCall = 'caller'
    },
    onAnotherDevice: (state) => {
      state.isRinging = false
      state.clientId = null
      state.topicId = null
      state.partnerTypeOffer = null
      state.localTypeOffer = null
      state.partnerSocketID = null
    },
    onOffer: (state, action) => {
      state.offer = action.payload
    },
    endCallAction: (state, action) => {
      state.callStarted = false
      state.clientId = ''
      state.partnerTypeOffer = ''
      state.localTypeOffer = ''
      state.isRinging = false
      state.isAcceptedOnAnotherDevice = false
      state.isUserBusy = false
      state.partnerSocketID = ''
      state.typeOfCall = ''
      state.offer = null
    },

    addMessageCall: (state, action) => {
      const { topicId, clientId, messageObject } = action.payload

      if (state.clientId === clientId && state.topicId === topicId) {
        state.messages.push(messageObject)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isLoading = false
        state.messages = action.payload.data.messages
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.chat = []
      })
  },
})

export const {
  onStopCalling,
  onOutcomingCall,
  onIncomingCall,
  onStartRinging,
  onAcceptCall,
  onOffer,
  endCallAction,
  onStopRinging,
  addMessageCall,
  onAnotherDevice,
} = callSlice.actions
export default callSlice.reducer

export const selectLayouts = (state) => state.call
