import Cookies from 'js-cookie'

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localStorage
const info = Cookies.get('session') && JSON.parse(Cookies.get('session'))

const initialState = {
  info: info ? info : null,
  success: false,
  error: false,
  message: '',
}

// Register user
export const register = createAsyncThunk(
  'auth/register',
  async (info, thunkAPI) => {
    try {
      return await authService.register(info)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Login user
export const login = createAsyncThunk('auth/login', async (info, thunkAPI) => {
  try {
    return await authService.login(info)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout()
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.success = false
      state.error = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      // Register
      .addCase(register.fulfilled, (state, action) => {
        state.success = true
        state.info = action.payload
      })
      .addCase(register.rejected, (state, action) => {
        state.error = true
        state.message = action.payload
        state.info = null
      })
      // Login
      .addCase(login.fulfilled, (state, action) => {
        state.success = true
        state.info = action.payload
        // state.info.user.role = 'Astrology'
      })
      .addCase(login.rejected, (state, action) => {
        state.error = true
        state.message = action.payload
        state.info = null
      })
      // Logout
      .addCase(logout.fulfilled, (state) => {
        state.info = null
      })
  },
})

export const { reset } = authSlice.actions
export default authSlice.reducer

export const selectUser = (state) => state.auth.info
export const selectUserData = (state) => state.auth.info.user
