import axios from 'axios'
import { store } from '@store/store'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_PORT,
})

axiosInstance.interceptors.request.use((config) => {
  const { auth } = store.getState()
  const token = auth.info.token

  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  }

  return config
})

export default axiosInstance
