import axios from '@api/axios'
import Cookies from 'js-cookie'

const API_URL = '/api/'

const createSessionCookie = (user) => {
  // Set the expiration time to a date far in the future (e.g., 1 year from now)
  const expirationDate = new Date()
  expirationDate.setFullYear(expirationDate.getFullYear() + 1)

  // Convert the expiration date to a GMT string
  const expires = '; expires=' + expirationDate.toGMTString()

  // Set the cookie with the updated expiration date
  document.cookie =
    'session' + '=' + JSON.stringify(user) + expires + '; path=/'
}

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData)

  if (response.data) {
    localStorage.setItem('auth', JSON.stringify(response.data))
  }

  return response.data
}

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'auth/advisor/login', userData)

  if (response.data) {
    localStorage.setItem('auth', JSON.stringify(response.data))
  }

  createSessionCookie(response.data)

  return response.data
}

// Logout user
const logout = () => {
  Cookies.remove('session')
}

const authService = {
  register,
  logout,
  login,
}

export default authService
