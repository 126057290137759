import axios from '@api/authAxios'
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'

const API_URL = '/api/messages/advisor'

const initialState = {
  conversations: null,
  client: null,
  topics: null,
  topicId: null,
  topicIdOnline: null,
  openChatSessionsIds: [],
  chatId: null,
  messages: null,
  isLoading: false,
  isSuccess: true,
  isError: false,
  errorMessage: '',
}

const handleError = (error) => {
  return (
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()
  )
}

// Get user all converasations
export const getConversations = createAsyncThunk(
  'chat/getConversations',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${API_URL}/all`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

// Get conversation with guest
export const getConversationWithClient = createAsyncThunk(
  'chat/getConversationWithClient',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${API_URL}/chat/${id}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

export const changeTopicConversationWithClient = createAsyncThunk(
  'chat/changeTopicConversationWithClient',
  async ({ topicId, clientId }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_URL}/topic/${topicId}/${clientId}`
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

// Send message to server
export const sendMessageServer = createAsyncThunk(
  'chat/sendMessageServer',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/send`, data)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)
// Leave conversatons
export const leaveConversation = createAsyncThunk(
  'chat/leaveConversation',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(`api/messages/leave-conversation`, {
        advisor_uuid: thunkAPI.getState().auth.info.user.uuid,
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(handleError(error))
    }
  }
)

const onSendUpdateConversation = (state, clientId, body) => {
  const updated = state.conversations[clientId]
  updated.message = body
  updated.time = Date.now()

  delete state.conversations[clientId]

  state.conversations = {
    [clientId]: updated,
    ...state.conversations,
  }
}

const onGetUpdateConversation = (state, clientId, body, activeChat, call) => {
  // Two updates here, check if that conversation is active or not
  const updated = state.conversations[clientId]
  updated.message = body
  updated.time = Date.now()
  updated.unread = (!activeChat && Number(updated.unread) + 1) || 0
  if (!call) delete updated.call
  if (call) updated.call = call
  delete state.conversations[clientId]
  state.conversations = {
    [clientId]: updated,
    ...state.conversations,
  }
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // Status
    clientChangeStatus: (state, action) => {
      const { clientId, topicId, status } = action.payload

      if (!state?.conversations?.[clientId]) {
        // Add new conversation here
        state.conversations = {
          ...state.conversations,
          [clientId]: {
            name: 'New Conversation',
            message: null,
            timestamp: Date.now(),
            unread: 0,
            blocked: true,
          },
        }
      }

      state.conversations[clientId] = {
        ...state.conversations[clientId],
        status: status ? 'online' : 'offline',
        topicIdOnline: topicId,
      }

      if (state.client && clientId === state.client.clientId) {
        state.client.status = status ? 'online' : 'offline'
        state.topicIdOnline = topicId
      }
    },

    changeOpenChatSessionsIds: (state, action) => {
      const { status, chatId } = action.payload

      if (status === 'add') {
        state.openChatSessionsIds = [...state.openChatSessionsIds, chatId]
      } else if (status === 'remove') {
        state.openChatSessionsIds = state.openChatSessionsIds.filter(
          (el) => el !== chatId
        )
      }
    },
    updateOpenChatSessionsIds: (state, action) => {
      state.openChatSessionsIds = action.payload
    },

    // Messages
    sendMessage: (state, action) => {
      const { clientId, topicId, body, messageObject } = action.payload
      onSendUpdateConversation(state, clientId, body)

      if (
        state.client &&
        state.client.clientId === clientId &&
        state.topicId === topicId
      ) {
        state.messages.push(messageObject)
      }
    },

    getMessage: (state, action) => {
      const { topicId, clientId, messageObject } = action.payload

      if (state.conversations && state.conversations[clientId]) {
        const activeChat =
          state.client !== null && clientId === state.client.clientId

        const clientSender = messageObject.sender === clientId

        const countHandler = activeChat || !clientSender ? true : false

        // Update conversation
        onGetUpdateConversation(
          state,
          clientId,
          messageObject.content,
          countHandler,
          messageObject.callState
        )

        // Update topics
        if (activeChat && state.topicId !== topicId) {
          state.topics = state.topics.map((topic) => {
            if (topic.topicId !== topicId) return topic

            return {
              ...topic,
              unread: topic.unread == 0 ? 1 : topic.unread + 1,
            }
          })
        }

        // Update messages
        if (activeChat && state.topicId === topicId) {
          state.messages.push(messageObject)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all messages
      .addCase(getConversations.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        state.isSuccess = true
        state.isLoading = false
        state.conversations = action.payload.data
      })
      .addCase(getConversations.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.conversations = {}
      })
      // Get conversation with guest
      .addCase(getConversationWithClient.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getConversationWithClient.fulfilled, (state, action) => {
        const { topics, chat, chatId, ...client } = action.payload.data
        state.isSuccess = true
        state.isLoading = false

        state.conversations[client.clientId] = {
          ...state.conversations[client.clientId],
          name: client.name,
          email: client.email,
        }

        state.chatId = chatId
        state.topicIdOnline = state.conversations[client.clientId].topicIdOnline
        state.client = client
        state.client.status = state.conversations[client.clientId].status
        state.topics = topics
        state.topicId = chat.topicId
        state.messages = chat.messages
        state.conversations[client.clientId].unread = 0
      })
      .addCase(getConversationWithClient.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.errorMessage = action.payload

        state.topicIdOnline = null
        state.client = null

        state.topics = null
        state.topicId = null
        state.messages = null
      })
      // Change conversation topic with client
      .addCase(changeTopicConversationWithClient.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(changeTopicConversationWithClient.fulfilled, (state, action) => {
        const { topicId, messages, chatId } = action.payload.data
        state.isSuccess = true
        state.isLoading = false

        // TODO
        state.chatId = chatId
        state.topicId = topicId
        state.messages = messages
        state.topics = state.topics.map((el) => {
          if (el.topicId === topicId) {
            return { ...el, unread: 0, isActive: true }
          } else {
            return { ...el, isActive: false }
          }
        })
      })
      .addCase(changeTopicConversationWithClient.rejected, (state, action) => {
        state.isError = true
        state.isLoading = false
        state.errorMessage = action.payload
      })
      // Send message to server
      .addCase(sendMessageServer.fulfilled, (state, action) => {
        console.log('Message sended from redux...')
      })
      .addCase(sendMessageServer.rejected, (state, action) => {
        state.isError = true
      })
      // Leave conversation
      .addCase(leaveConversation.fulfilled, (state, action) => {
        console.log('Conversation leaved')
      })
      .addCase(leaveConversation.rejected, (state, action) => {
        state.isError = true
        state.errorMessage = action.payload
      })
  },
})

export const {
  clientChangeStatus,
  sendMessage,
  getMessage,
  changeOpenChatSessionsIds,
  updateOpenChatSessionsIds,
  getCallData,
} = chatSlice.actions
export default chatSlice.reducer

export const selectAllMessages = (state) => state.chat.chats
